// Our colours
$dark-grey: #4a4a4a;
$grey: #9e9d9d;
$light-grey: #e6e6e6;
$off-white: #fcfdfc;
$raisin-black: #171a21;
$green: #12928b;
$dark-blue: #017bae;
$pale-blue: #cef3f6;
$pale-green: #78e9a5;
$purple: #7c7cfe;
$tan: #f3efeb;
$space-cadet-blue: #2A2E43;
$teal-blue: #598392;
$fire-opal: #e85f5c;

// Override bulma
$primary: $raisin-black;
$black: $raisin-black;
$white: $off-white;
$info: $teal-blue;

$table-row-hover-background-color: $pale-blue;
