@import "~trix/dist/trix";
@import "~tippy.js/dist/tippy";
@import "~animate.css/animate";
// This is to get around a weird bug in parcel https://github.com/parcel-bundler/parcel/issues/1875
@import "variables";
@import "common";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~simple-datatables/dist/style";

h3 {
  color: red;
}

@import "~bulma/bulma";

body {
  background-color: #fcfdfc;
}

.button.is-primary:hover {
  background-color: $raisin-black;
  transition: background-color 0.2s;
}

.button.is-secondary {
  @extend .button, .is-primary;

  background-color: $raisin-black !important;

  &:hover {
    background-color: $primary;
    transition: background-color 0.2s;
  }
}

.menu-list {
  a {
    font-size: 1.1rem;
  }
}

.menu-logo {
  max-width: 12vw;
  display: block;
}

.menu-list:not(:last-child) {
  padding-bottom: .5rem;
  border-bottom: 2px solid $light-grey;
}

.menu-list a:hover {
  background-color: $raisin-black;
  color: $off-white;
}

.logo-title {
  min-height: 3.25rem;
  padding: 0 1.5rem;

  .title {
    line-height: 1.5;
  }
}

.card {
  header {
    padding: 20px;
  }
}


.form-section {
  padding-bottom: 3rem;
}

[data-controller="sortable"]:hover {
  cursor: pointer;
}

.is-clickable {
  cursor: pointer;
  pointer-events: initial !important;
}

.navbar-logo {
  height: 1.75rem;
}

.unsubscribe__card-content{
  text-align: center;
  padding: 20px;
}

.message {
  margin-top: 20px;
  text-align: center;
}

.nav-buttons {
  text-align: center;
}

.logo-container {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  margin-top: 10px;
}

.unsubscribe__message{
  @extend .message
}

.unsubscribe__logo-container {
  @extend .logo-container;
}

.unsubscribe__logo {
  @extend .logo;
}

.unsubscribe__card {
  margin: 10%
}

.unsubscribe__nav-buttons {
  @extend .nav-buttons
}