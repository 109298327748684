.error-page__logo-container {
  @extend .logo-container;
}

.error-page__logo {
  @extend .logo;
}

.error-page__card {
  margin: 10%
}

.error-page__message {
  @extend .message;
}

.error-page__nav-buttons {
  @extend .nav-buttons
}